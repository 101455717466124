import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cover-letter"
export default class extends Controller {
  static targets = ["input", "output"];

  update(event) {
    const type = event.target.dataset.outputType;
    const outputs = this.outputTargets.filter(t => t.dataset.outputType === type);
    outputs.forEach(output => output.textContent = event.target.value);
  }
}
